import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import css from "@styled-system/css"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "banner-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 650, maxHeight: 500, cropFocus: EAST) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tabletImage: file(relativePath: { eq: "banner-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "banner-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1300, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      xlImage: file(relativePath: { eq: "banner-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, maxHeight: 450, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = data && [
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: `(max-width: 650px)`,
    },
    {
      ...data.tabletImage.childImageSharp.fluid,
      media: `(max-width: 1000px)`,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(max-width: 1450px)`,
    },
    data.xlImage.childImageSharp.fluid,
  ]

  return <ResponsiveImage fluid={sources} sources={sources} />
}

const ResponsiveImage = styled(Img)`
  > div:first-of-type {
    ${props =>
      css(() => {
        let sizes = {}
        props.sources
          .filter(item => item.media)
          .forEach((e, i, arr) => {
            sizes = {
              ...sizes,
              [`@media ${arr[arr.length - (i + 1)].media}`]: {
                paddingBottom: `${
                  100 / arr[arr.length - (i + 1)].aspectRatio
                }% !important`,
              },
            }
          })
        return {
          paddingBottom: `${
            100 / props.sources[props.sources.length - 1].aspectRatio
          }% !important`,
          ...sizes,
        }
      })}
  }
`

export default Image
