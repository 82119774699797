import React, { useRef } from "react"
import {
  RootFront,
  Container,
  Box,
  ContactForm,
  HeroPrimary,
  Body,
  WideNarrow,
  ContactDetails,
  MainNavDesktop,
  ButtonPrimary,
} from "../components"
import HeroOne from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const contactRef = useRef()
  const scrollContactInView = () => {
    contactRef &&
      contactRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }
  return (
    <RootFront>
      <SEO title="Coming Soon" />
      <MainNavDesktop onClick={scrollContactInView} />
      <HeroPrimary
        title="Coming Soon"
        subtitle={
          <span>
            Bringing <Box as="br" display={{ _: "inline", sm: "none" }} />
            Innovation
            <Box as="br" display={{ _: "inline", sm: "none" }} /> to Life
          </span>
        }
      >
        <Body>
          Phase Health is a global medical communications agency dedicated to
          supporting life science companies successfully communicate their
          medical advances in Hematology, Oncology and Immunology.
        </Body>
        <ButtonPrimary onClick={() => scrollContactInView()} mt="2rem">
          Contact Us
        </ButtonPrimary>
      </HeroPrimary>
      <Container>
        <Box overflow="hidden" mr="calc(50% - 50vw)">
          <HeroOne />
        </Box>
      </Container>
      <WideNarrow bgRight="primary.base">
        <Box py="3rem">
          <ContactDetails />
        </Box>
        <Box
          color="white"
          ref={contactRef}
          py="3rem"
          pl={{ _: "0rem", md: "5rem" }}
        >
          <ContactForm />
        </Box>
      </WideNarrow>
    </RootFront>
  )
}

export default IndexPage
